<template>
  <ul id="main-menu-navigation" class="nav navbar-nav">
    <component
      :is="resolveNavComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import navMenuItems from "@/navigation/horizontal";
import HorizontalNavMenuHeaderLink from "../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue";
import HorizontalNavMenuHeaderGroup from "../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue";
import store from '@/store';

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    resolveNavComponent(item) {
      let user_data = store.getters["user/getUserDetails"];
      if (item.user_types.includes(user_data.user_role_id)) {
        if (item.children) return "horizontal-nav-menu-header-group";
        return "horizontal-nav-menu-header-link";
      } else {
        return null;
      }
    },
  },
  // setup() {
  //   const resolveNavComponent = item => {
  //     if (item.children) return 'horizontal-nav-menu-header-group';
  //     return 'horizontal-nav-menu-header-link';
  //   }

  //   return {
  //     resolveNavComponent,
  //   }
  // },
};
</script>
